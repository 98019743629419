.projects-section-heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects-section-heading h1 {
  text-align: center;
}

.projects-section-heading-border {
  margin: auto;
  border: 2px solid rgb(32, 35, 126);
  padding: 1em;
  width: fit-content;
  border-radius: 25px;
}

.projects-section-heading-wrapper {
  width: fit-content;
  border-radius: 25px;
  border: 2px solid rgb(32, 35, 126);
}

/* project navigation  */

.project-navigation-wrapper {
  padding: 1em 2em 0.5em 2em;
}

.project-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.next-handle,
.prev-handle {
  position: relative;
  font-size: 2rem;
}

.next-handle-wrapper,
.prev-handle-wrapper {
  border-radius: 90%;
  cursor: pointer;
}

/* project subheading  */

.project-subheading {
  padding: 1em;
}

.subheading-name-wrapper h2 {
  position: relative; /* Make sure the subheading wrapper is positioned relative */
  width: fit-content;
  padding-bottom: 10px;
  cursor: pointer;
}

.subheading-name-wrapper h2::after {
  position: absolute;
  content: "";
  height: 2px;
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
  width: inherit;
  background: linear-gradient(90deg, rgb(24, 24, 24), rgb(20, 157, 255));
  width: 0;
  bottom: 0; /* Position the line at the bottom */
  left: 0; /* Start the line from the left */
  transition: width 0.3s ease; /* Smooth transition effect */
}

.subheading-name-wrapper h2:hover::after {
  width: 100%;
}

.subheading-name-wrapper h2 {
  font-family: "Courier New", Courier, monospace;
  font-weight: 900;
  color: rgb(255, 204, 75);
}

.project-text {
  word-spacing: 7px;
  font-size: 1.2rem;
  font-weight: 400;
  color: rgb(255, 255, 255);
  padding: 1.2rem;
}

/* project section  */

.project-section-wrapper{
    width: 80%;
    margin: auto;
}

.project-section-wrapper h2 {
  width: fit-content;
}

.project-section-wrapper {
  padding: 1em;
}

.project-section {
  border: 2px solid rgb(15, 185, 15);
  border-radius: 20px;
}

.waves-animation {
  border-radius: 20px;
}

.stable-wrapper {
  border-radius: 20px;
}

/* sections  */

.features-wrapper {
  padding: 1em;
  padding-left: 2rem;
}

.features{
    display: flex;
    flex-direction: column;
}

.feature{
    margin-bottom: 1rem;
}

.feature h3{
    color: rgb(55, 138, 255);
    margin-bottom: 1rem;
}

ul{
    margin-left: 2rem;
}

.techStack{
    display: flex;
    flex-direction: column;
}

.techStack-item{
    margin-bottom: 1rem;
}

.techStack-item h3{
    color: rgb(55, 138, 255);
    margin-bottom: 1rem;
}

/* live demo  */

.livedemo{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
}

.livedemo-btn{
  position: relative;
  padding: 1em;
  border-radius: 40px;
  box-shadow: 0 0 20px rgb(89, 0, 255);
  width: 25rem;
  height: 7rem;
  font-size: 2rem;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
  border: 2px solid rgb(163, 163, 163);
  background-color: rgb(6, 58, 189);
  overflow:hidden;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.livedemo-btn span{
  z-index: 10;
  position: sticky;
  top: 20%;
}

.livedemo-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgb(139, 22, 181);
  transition: left 0.3s ease-in-out;
}

.livedemo-btn:hover::before {
  left: 0;
}

.livedemo-btn:active{
  transform: scale(0.9);
  transition: all 0.3s;
}

@media (max-width: 700px){
   .livedemo-btn{
        width: 18rem;
        height: 5rem;
        font-size: 1.5rem;
    }
}


@media (max-width: 520px) {
  .livedemo-btn{
    width: 100%;
    height: 3rem;
    font-size: 1em;
}
}

