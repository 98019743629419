
.skill-section-heading{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skill-section-heading h1{
    text-align: center;
}

.skill-section-heading-border{
    margin: auto;
    border: 2px solid rgb(32, 35, 126);
    padding: 1em;
    width: fit-content;
    border-radius: 25px;
}

.skill-section-heading-wrapper{
    width: fit-content;
    border-radius: 25px;
    border: 2px solid rgb(32, 35, 126);
}

/* ------------------ */

.skill-globe-container{
    width: 100%;
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}

.skill-globe-wrapper::after{
    position: absolute;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 900;
    bottom: 5px;
    content: '';
    font-size: 2em;
    color: rgb(210, 211, 230);
    padding: 10px;
    margin: 10px;
    box-shadow: 0 0 30px blueviolet;
    border-radius: 20px;
}

.skill-globe-wrapper{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    width: fit-content;
    margin-top: 1em;
}