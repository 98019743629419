*{
  margin: 0;
  padding: 0;
  transition: all 0.1s ease;
}

body{
  background-color: black;
  color: white;
  overflow-x: hidden;
}

main canvas{
  position: fixed;
  top: 0;
  z-index: -1;
}
