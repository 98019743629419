
.intro-wrapper{
    height: 80vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.intro{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 34vh;
    max-width: 50%;
}

.hello-section{ 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-radius: 20px;
    padding: 10px;
    border: 2px solid green;
    font-size: 20px;
    box-shadow: 0 0 20px rgb(64, 255, 64);
}

.highlight-intro{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1rem;
    color: rgb(255, 153, 63);
    text-shadow: 2px 2px rgb(255, 0, 0);
    text-align: center;
}

.extra-intro{
    font-size: 20px;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media (max-width:799px){
  .highlight-intro{
    font-size: 1.5rem;
  }

  .intro{
    max-width: 100%;
  }

  .intro-wrapper{
    flex-direction: column-reverse;
  }

  .extra-intro{
    padding:1em;
    font-size: medium;
  }
}

@media (max-width:670px){
    .intro{
        margin-left: 10px;
        margin-right: 10px;
    }
    .highlight-intro{
        margin: 1em auto;
        font-size: 1rem;
    }
    .extra-intro{
        padding: 1em;
        font-size: large;
        text-align: center;
        width:70%;
    }
}


@media (max-width:450px) {
    .profile-pic{
        width:100%;
    }
}

/* profile pic  */

.profile-pic-wrapper, .profile-pic-border, .profile-pic, .profile-pic-outer-cover{
    border-radius: 50%;
}

.profile{
    width: 50%;
}

.profile-pic{
    width:100%;
    box-shadow: 0px 0px 100px rgb(189, 189, 255);
}

.profile-pic-outer-cover{
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-pic-border{
    margin: auto;
    border: 5px solid rgb(32, 35, 126);
    width: fit-content;
}

.profile-pic-wrapper{
    width: fit-content;
    border: 2px solid rgb(32, 35, 126);
}

