.navbar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: large;
}

.nav-wrapper {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    border: 2px solid rgb(32, 35, 126);
    border-radius: 25px;
    font-weight: 900;
    font-family:'Courier New', Courier, monospace;
}

.wrapper {
    border-radius: 25px;
}

@media (max-width: 550px){
    .navbar{
        font-size: small;
    }
    .nav-wrapper{
        font-weight:500;
        padding:0.5em;
    }
}

@media (max-width: 380px){
    .navbar{
        font-size: x-small;
    }
    .nav-wrapper{
        font-weight: unset;
    }
}