.contact-section-heading{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-section-heading h1{
    text-align: center;
}

.contact-section-heading-border{
    margin: auto;
    border: 2px solid rgb(32, 35, 126);
    padding: 1em;
    width: fit-content;
    border-radius: 25px;
}

.contact-section-heading-wrapper{
    width: fit-content;
    border-radius: 25px;
    border: 2px solid rgb(32, 35, 126);
}

/* contact tiles  */

.contact-tiles-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: auto;
    height: 80vh;
    /* overflow: hidden; */
}

.contact-tiles{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    width: 100%;
} 

.contact-tile{
    position: relative;
    border-radius: 25px;
    box-shadow: 0 0 20px rgb(43, 43, 43);
    padding: 1rem 2rem;
    display: flex;
    margin: 1em auto;
    width: 20rem;
    font-size: 1.2rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
    cursor: pointer;
    overflow: hidden;
}

.contact-tile::before{
    content: "";
    width: 100%;
    top:0;
    height:100%;
    left:-100%;
    position: absolute;
    background-color: rgb(153, 153, 153);
    border-radius: 25px;
    z-index: 5;
    transition: left 0.3s ease-in-out;
}

.contact-tile:hover::before{
    left:0;
}

.contact-tile span{
    z-index: 10;
}

.contact-tile img{
    width: 30px;
    height: 30px;
    margin-right: 1rem;
}

.linkedin{
    background-color: rgb(51, 51, 255);
}

.email{
    background-color: rgb(255, 255, 255);
    color: black;
}

.stackoverflow{
    background-color: rgb(255, 255, 255);
    color: black;
}

@media (max-width: 529px) {
    .contact-tile{
        margin: 2rem 0;
        padding: 1em;
        font-size: 1em;
        width: 100%;
    }
    .contact-section-heading{
        font-size: medium;
    }
}